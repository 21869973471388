<template>
  <div class="columns is-centered mt-5">
    <div class="column is-3 box mt-5 p-5">
      <div
        v-if="completed"
      >{{t('auth.resetInfo')}}</div>
      <form class="container pad-05" v-else>
        <ValidationObserver ref="observer" v-slot="{ invalid }">
          <ValidationProvider rules="required|email" v-slot="{ errors, valid }">
            <b-field :message="t(errors[0])" :type="{ 'is-danger': errors[0], 'is-primary': valid }">
              <b-field
                :label="t('common.email')"
                :type="{ 'is-danger': errors[0], 'is-primary': valid }"
                label-position="on-border"
              >
                <b-input
                  custom-class="has-text-centered"
                  @keydown.native.enter="requestPasswordReset"
                  v-model="email"
                  icon="email"
                  expanded
                ></b-input>
                <p class="control">
                  <b-button
                    type="is-primary"
                    :disabled="invalid"
                    @click="requestPasswordReset"
                    label="Wyślij"
                  ></b-button>
                </p>
              </b-field>
            </b-field>
          </ValidationProvider>
        </ValidationObserver>
      </form>
      <p class="has-text-centered mt-2">
        <b-button @click="$router.push({name: 'login'})" size="is-small" type="is-text">{{t('common.return')}}</b-button>
      </p>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { Action } from '@/store/config_actions'
import CommonMixins from '@/mixins/commons'

export default {
  name: 'PassResetRequestView',

  components: {
    ValidationProvider, ValidationObserver
  },

  mixins: [CommonMixins],

  data: function () {
    return {
      email: null,
      completed: false
    }
  },

  methods: {
    requestPasswordReset() {
      if (this.email != null) {
        // Dispatch API call.
        this.$store.dispatch(Action.AUTH_RESET_REQUEST, this.email)
        this.completed = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>